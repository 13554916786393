<script>
import appConfig from "@/app.config.json";

export default {
  page: {
    title: "Maintenance",
    meta: [{ name: "description", content: appConfig.description }]
  },
}
</script>
<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link tag="a" to="/" class="text-dark">
        <i class="fas fa-home h2"></i>
      </router-link>
    </div>
    <section class="my-4 pt-4">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="home-wrapper">
              <div class="mb-2">
                <img src="@/assets/images/logo-light.png" alt="logo" width="300" />
              </div>

              <div class="row justify-content-center">
                <div class="col-sm-8">
                  <div class="maintenance-img">
                    <img src="@/assets/images/soon.png"  alt class="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="text-center ">

                    <div class=" text-center">
                      <router-link tag="a" class="btn btn-primary" to="/">Back to Dashboard</router-link>
                    </div>
                  </div>
                </div>
              </div>



              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>